import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Tag } from 'antd';

import { getBillingConfigurations } from '../../../../store/account/account-actions';
import CustomerInfo from './CustomerInfo';
import ContractInfo from './ContractInfo';
import FeesAndBilling from './FeesAndBilling';
import PaymentMethod from './PaymentMethod';
import Territories from './Territories';
import {
  CollapsePanel,
  CollapsableSubsectionTitle,
  Subsection,
  SubsectionTitle,
  SubsectionSubtitle,
} from '../../styled';
import Spin from '../../../../components/spin';
import { CustomCollapse } from '../../../../components/collapse/styled';
import {
  AccountStatusType,
  getAccountStatus,
} from '../../../../utils/account-status';
import ProgramDetails from './ProgramDetails';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const sections = [
  { title: 'customerInfo', children: <CustomerInfo /> },
  { title: 'contractInfo', children: <ContractInfo /> },
  { title: 'feesAndBilling', children: <FeesAndBilling /> },
  { title: 'territories', children: <Territories /> },
  { title: 'paymentMethod', children: <PaymentMethod /> },
  { title: 'programDetails', children: <ProgramDetails /> },
] as const;

const Contract = () => {
  const { t } = useTranslation('common', { keyPrefix: 'account.contract' });
  const dispatch = useAppDispatch();
  const { accountDetails, loading } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      loading: state.account.billingConfigurations.loading,
    }),
    shallowEqual,
  );
  const accountId = accountDetails?.id;

  const accountStatus = getAccountStatus(accountDetails);

  const { reviewingUser } = accountDetails?.liveRequest || {};

  useEffect(() => {
    dispatch(getBillingConfigurations());
  }, [dispatch, accountId]);

  return loading ? (
    <Spin />
  ) : (
    <div>
      <Subsection>
        <SubsectionTitle>
          <div>
            <h2>{t('accountStatus.title')}</h2>
            {reviewingUser && (
              <SubsectionSubtitle>
                {t('accountStatus.description', {
                  approver: reviewingUser.email,
                  date: moment(
                    accountDetails?.liveRequest?.reviewTimestamp,
                  ).format('ll'),
                })}
              </SubsectionSubtitle>
            )}
          </div>
          <Tag color={tagColor[accountStatus]}>
            {t(`accountStatus.status.${accountStatus}`)}
          </Tag>
        </SubsectionTitle>
      </Subsection>
      <CustomCollapse>
        {sections.map(({ title, children }, idx) => (
          <CollapsePanel
            key={title}
            header={
              <CollapsableSubsectionTitle number={idx + 1}>
                {t(`${title}.title`)}
              </CollapsableSubsectionTitle>
            }
          >
            {children}
          </CollapsePanel>
        ))}
      </CustomCollapse>
    </div>
  );
};

const tagColor: { [status in AccountStatusType]: string } = {
  live: 'green',
  test: 'default',
  review: 'blue',
  rejected: 'red',
  subscribed: 'green',
  undefined: 'default',
};

export default Contract;
