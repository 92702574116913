import { AxiosError, AxiosResponse } from 'axios';

import { StatusCode } from '../../store/status/status-reducer';

export type Error = {
  code: StatusCode;
  message: string;
};

export function actionToError(action: any = {}): Error {
  const { data } = action.payload?.error?.response || {};
  const { code, message, errMessage } = data?.error ||
    data || {
      code: 'error',
      message: 'Unexpected error',
    };

  return {
    code,
    message: message || errMessage,
  };
}

export function parseAxiosError(error: AxiosError): Error {
  const { data } = error?.response || {};
  const { code, message } = data?.error || {
    code: 'error',
    message: 'Unexpected error',
  };

  return {
    code,
    message,
  };
}

export async function filterGetByIdCall(
  id: string,
  programId: string | undefined,
  httpCall: (id: string) => Promise<AxiosResponse<any>>,
  conditions: ((element: any) => boolean)[],
) {
  try {
    const response = await httpCall(id);
    return {
      ...response,
      data: {
        items: response.data.items.filter(
          (element: any) =>
            element.programId === programId &&
            conditions.every(condition => condition(element)),
        ),
      },
    };
  } catch (error) {
    return error as any;
  }
}

export function getDataItem<T = any>({ items }: { items: T[] }) {
  return items ? items[0] : ({} as T);
}
