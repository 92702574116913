import React, { ReactNode, useEffect } from 'react';
import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

interface ErrorBoundaryState {
  error?: any;
  children?: ReactNode;
}

const ErrorMessage = ({ error, children }: ErrorBoundaryState) => {
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Empty description={t('error.somethingWentWrong')}>
      <Button
        type="primary"
        data-testid="sentry-report-btn"
        onClick={() => Sentry.showReportDialog()}
      >
        {t('error.reportSentry')}
      </Button>
    </Empty>
  );
};

export default class ErrorBoundary extends React.Component<
  unknown,
  ErrorBoundaryState
> {
  constructor(props: unknown) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return error ? <ErrorMessage error={error} /> : children;
  }
}
