import { PublisherInfo } from '@fidel.uk/types/lib/unique-offer';
import { CountryCode, WeekDaysIndexes } from '../../types';

export const offerTypes = [
  'awaitingApproval',
  'expired',
  'live',
  'marketplace',
  'requests',
  'upcoming',
] as const;
export type OfferType = typeof offerTypes[number];

export enum PaymentType {
  AUTOMATIC = 'credit_card',
  MANUAL = 'bank_transfer',
}

export interface DiscountType {
  name: string;
  value: number;
  maxRewardAmount?: number | null;
}

export interface PaymentInfo {
  type: PaymentType;
  stripeToken?: string;
  email?: string;
}

type AccountType = {
  type: 'merchant' | 'card-linking' | 'affiliate';
  id: string;
};

export const Channels = {
  ONLINE: 'online',
  IN_STORE: 'in-store',
} as const;

export type OfferChannels = typeof Channels[keyof typeof Channels];

export interface ContentProviderMetadata {
  payment?: {
    maxTimeline?: number;
    channel?: OfferChannels[];
  };
  approval?: {
    type: typeof UniqueOfferApprovalTypes[keyof typeof UniqueOfferApprovalTypes];
  };
  commission?: {
    minCardholder?: number;
  };
  customerRecency?: {
    enabled?: boolean;
    days?: number;
  };
  description?: {
    short?: string;
    long?: string;
  };
}

export const UniqueOfferApprovalTypes = {
  STA: 'subject-to-approval',
  NON_STA: 'auto-approved',
} as const;

export type OfferMetadata = Record<
  string,
  string | boolean | ContentProviderMetadata | CustomerRecency | ProgramInfo
>;

export const protectedMetadataPropsList = [
  'contentProvider',
  'customerRecency',
  'programInfo',
  'supplierEmail',
  'supplierId',
  'supplier',
  'minCashback',
  'isOnline',
  'isInStore',
] as const;

export type ProtectedMetadataPropsValues =
  typeof protectedMetadataPropsList[number];

export type OfferSupplierFields = Record<string, string>;

type Enabled = {
  enabled: boolean;
};

export interface OfferCardActivation extends Enabled {
  qualifiedTransactionsLimit?: number;
}

export interface CustomerRecency extends Enabled {
  days?: number;
}

interface DirectOffer {
  accepted: boolean;
  activation: OfferCardActivation;
  additionalTerms: string;
  brandId: string | undefined;
  brandLogoURL: string | undefined;
  brandName: string | undefined;
  channels: OfferChannels[] | undefined;
  countryCode: CountryCode;
  created: string;
  currency: string;
  customerRecency?: CustomerRecency;
  daysOfWeek: WeekDaysIndexes[];
  endDate: string | null;
  estimationApprovalDate?: string;
  estimationLiveDate?: string;
  feeSplit?: number | null;
  funded: AccountType | null;
  hasQualifiedTransactions?: boolean;
  id: string | undefined;
  locationsFile?: unknown;
  locationsTotal: number;
  maxTransactionAmount?: number;
  metadata: OfferMetadata | null;
  minCashback?: number;
  minTransactionAmount: number;
  name: string;
  origin: AccountType;
  performanceFee: number;
  publisherId: string;
  publisherInfo?: PublisherInfo;
  requestedDate?: string;
  requiresApproval?: boolean;
  returnPeriod: number | null;
  schemes: string[] | undefined;
  startDate: string;
  supplier: string;
  supplierId?: string;
  supplierInfo?: Record<string, string>;
  supplierRequiredFields?: string[];
  type: DiscountType;
  updated: string;
  userId: string | undefined;
}

export interface ProgramInfo {
  programId: string;
  programName: string;
}

export const programLinkStatuses = [
  'linked',
  'pending',
  'processing_configuration',
  'processing_approval',
  'rejected',
] as const;
export interface ProgramLinkStatus {
  status: typeof programLinkStatuses[number];
  id: string;
}

interface UniqueOfferProps extends ProgramInfo {
  programsLinkStatus: ProgramLinkStatus[];
  featured?: number;
  isAccountProgramUniqueOffer?: boolean;
}

export interface UniqueOffer extends DirectOffer, UniqueOfferProps {}

export interface Offer extends DirectOffer, Partial<UniqueOfferProps> {}

export function transformToOffer(data: any): Offer {
  return {
    accepted: data.accepted,
    activation: data.activation,
    additionalTerms: data.additionalTerms,
    brandId: data.brandId,
    brandLogoURL: data.brandLogoURL,
    brandName: data.brandName,
    channels: data.channels,
    countryCode: data.countryCode,
    created: data.created,
    currency: data.currency,
    daysOfWeek: data.daysOfWeek || [0, 1, 2, 3, 4, 5, 6],
    endDate: data.endDate,
    estimationApprovalDate: data.estimationApprovalDate,
    estimationLiveDate: data.estimationLiveDate,
    featured: data.featured,
    feeSplit: data.feeSplit,
    funded: data.funded,
    hasQualifiedTransactions: data.hasQualifiedTransactions,
    id: data.id,
    isAccountProgramUniqueOffer: data.isUniqueOffer,
    locationsTotal: data.locationsTotal,
    maxTransactionAmount: data.maxTransactionAmount,
    metadata: data.metadata,
    minCashback: data.minCashback,
    minTransactionAmount: data.minTransactionAmount,
    name: data.name,
    origin: data.origin,
    performanceFee: data.performanceFee,
    programId: data.programId,
    programName: data.programName,
    programsLinkStatus: data.programsLinkStatus,
    publisherId: data.publisherId,
    publisherInfo: data.publisherInfo,
    requiresApproval: data.requiresApproval,
    returnPeriod: data.returnPeriod,
    requestedDate: data.requestedDate,
    schemes: data.schemes,
    startDate: data.startDate,
    supplier: data.supplier,
    supplierId: data.supplierId,
    supplierInfo: data.supplierInfo,
    supplierRequiredFields: data.supplierRequiredFields,
    type: data.type,
    updated: data.updated,
    userId: data.userId,
    customerRecency: data.customerRecency,
  };
}

export interface OfferOptions {
  last?: any;
  select?: string;
  status?: 'expired' | 'live' | 'pending' | 'requests' | 'upcoming';
}

export const enum OfferNotification {
  UPDATE_OFFER = 'updateOffer',
  CREATE_OFFER = 'createOffer',
  LINK_ALL_PROGRAM_LOCATIONS = 'linkAllProgramsLocationsToOffer',
  MANAGE_LOCATIONS_MANUALLY = 'manageLocationsManually',
  ADD_UNIQUE_OFFER = 'addUniqueOffer',
}
