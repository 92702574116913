import React, { useEffect, useState } from 'react';
import { Dropdown, Space } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ClearButton, DropdownButton, FilterNumber } from '../styled';
import { Caron, X } from '../../icons';
import Overlay from './Overlay';
import OverlayMultiple from './OverlayMultiple';

export const filterNames = [
  'activation',
  'approval',
  'availableProgramId',
  'brandId',
  'channel',
  'channelWithNotSpecified',
  'contentProviderId',
  'countryCode',
  'customerRecency',
  'endDate',
  'marketplaceCountries',
  'network',
  'programId',
  'uniqueBrandId',
  'returnPeriod',
  'offerStatus',
] as const;
export type FilterName = typeof filterNames[number];

type FilterOption = {
  id: string;
  name: string | React.ReactNode;
};

export interface FilterDropdownProps {
  name: FilterName;
  multiple?: boolean;
  onClear?: () => void;
  options: FilterOption[];
  search?: {
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    onLoadMore?: () => void;
  };
  selected: string[];
  setSelected: (option: string[]) => void;
}

export interface OverlayProps
  extends Omit<FilterDropdownProps, 'multiple' | 'name'> {
  setVisible: (visible: boolean) => void;
}

function FilterDropdown({
  name,
  multiple = false,
  onClear,
  options,
  search,
  selected,
  setSelected,
}: FilterDropdownProps) {
  const { t } = useTranslation('common');
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    FilterOption | undefined
  >(undefined);
  const form = useForm({
    defaultValues: selected.reduce((acc, val) => ({ ...acc, [val]: true }), {}),
  });

  const { reset } = form;
  const activeFilters = selected.length;

  const firstSelected = options.find(({ id }) => selected.includes(id));

  function clear() {
    setSelected([]);
    reset();

    if (onClear) onClear();
  }

  useEffect(() => {
    if (activeFilters === 1 && firstSelected) setSelectedOption(firstSelected);
  }, [activeFilters, firstSelected, selected]);

  return (
    <Space size={0}>
      <Dropdown
        overlay={
          multiple ? (
            <FormProvider {...form}>
              <OverlayMultiple
                search={search}
                setVisible={setVisible}
                setSelected={setSelected}
                options={options}
              />
            </FormProvider>
          ) : (
            <Overlay
              setVisible={setVisible}
              selected={selected}
              setSelected={setSelected}
              options={options}
            />
          )
        }
        trigger={['click']}
        visible={visible}
        onVisibleChange={() => setVisible(!visible)}
      >
        <DropdownButton $active={!!activeFilters}>
          {t(`filters.${name}.name`)}
          {!!activeFilters && (
            <span style={{ fontWeight: 600 }}>
              :{' '}
              {selectedOption && activeFilters === 1 ? (
                selectedOption.name
              ) : (
                <FilterNumber>{activeFilters}</FilterNumber>
              )}{' '}
            </span>
          )}
          <Caron />
        </DropdownButton>
      </Dropdown>
      {!!activeFilters && (
        <ClearButton data-testid={`clear-filter-${name}`} onClick={clear}>
          <X />
        </ClearButton>
      )}
    </Space>
  );
}

export default FilterDropdown;
