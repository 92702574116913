import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import UserOutlined from '@ant-design/icons/UserOutlined';

import memoNoProps from '../../../utils/memo-no-props';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import { StyledMenu, StyledMenuDivider } from '../../menu';
import {
  AvatarNameContainer,
  AccountName,
  Header,
  Name,
  Role,
  StyledDropdown,
  StyledNavMenuItem,
} from '../styled/account-menu';
import Ellipsis from '../../ellipsis';
import Avatar from '../../avatar';
import { Caron } from '../../icons';
import { useAppSelector } from '../../../store/hooks';

const AccountMenu = () => {
  const { t } = useTranslation();
  const { signOut } = useIsSignedIn();
  const { user } = useIsSignedIn();
  const accountName = useAppSelector(state => state.account.details?.name);

  function handleSignOutClick(event: any) {
    event.preventDefault();
    Auth.signOut();
    signOut();
  }

  const AccountAvatar = () => (
    <Avatar
      data-testid="avatar"
      shape="circle"
      icon={!accountName && <UserOutlined />}
      style={{ marginRight: '10px' }}
    >
      {accountName}
    </Avatar>
  );

  const menu = (
    <StyledMenu style={{ width: '230px', left: '-5px' }}>
      <Header>
        <AccountAvatar />
        <div>
          <Name>
            <Ellipsis>{accountName}</Ellipsis>
          </Name>
          <Role data-testid="role">
            {t(`account.role.${user?.role || 'developer'}` as any)}
          </Role>
        </div>
      </Header>
      <StyledMenuDivider />
      <StyledNavMenuItem>
        <NavLink to="/account">{t('accountMenu.settings')}</NavLink>
      </StyledNavMenuItem>
      <StyledNavMenuItem>
        <NavLink
          to="/sign-out"
          onClick={handleSignOutClick}
          data-testid="sign-out"
        >
          {t('accountMenu.signOut')}
        </NavLink>
      </StyledNavMenuItem>
    </StyledMenu>
  );

  return (
    <StyledDropdown overlay={menu} trigger={['click']}>
      <div>
        <AvatarNameContainer>
          <AccountAvatar />
          <AccountName>
            <Ellipsis>{accountName}</Ellipsis>
          </AccountName>
        </AvatarNameContainer>
        <Caron />
      </div>
    </StyledDropdown>
  );
};

export default memoNoProps(AccountMenu);
