import { ComponentProps } from 'react';
import { omit } from 'lodash';
import {
  Offer,
  OfferChannels,
  ProgramInfo,
  UniqueOffer,
  protectedMetadataPropsList,
} from '../../store/offers/offers-model';
import DetailTitle from '../../components/detail/components/DetailTitle';
import { withCurrency, capitalize } from '../../utils/transform';
import { AddUniqueOfferFormModel, OfferTitleDetails } from './types';
import { DOUBLE_MINUS } from '../../utils/special-character';
import { State } from '../../store';
import { filterProgramByType } from '../../store/programs/programs-selectors';

export const transformOfferToDetailTitle = ({
  brandLogoURL,
  brandName,
  name,
}: OfferTitleDetails): ComponentProps<typeof DetailTitle> => ({
  logo: brandLogoURL,
  name: brandName,
  title: name,
  subTitle: brandName,
});

export function amountWithUnit({ type, currency }: Offer, amount: number) {
  return type.name === 'amount' ? withCurrency(amount, currency) : `${amount}%`;
}

export function parseChannels(channels?: OfferChannels[]) {
  return channels ? channels.map(capitalize).sort().join(', ') : DOUBLE_MINUS;
}

export function parseSupplierInfoToObject(
  supplierInfo: AddUniqueOfferFormModel['supplierInfo'],
) {
  if (Array.isArray(supplierInfo)) {
    return supplierInfo.reduce(
      (result, item) => ({
        ...result,
        [item.key]: item.value,
      }),
      {},
    );
  }

  return supplierInfo;
}

export function isUniqueOffer(offer: Offer): offer is UniqueOffer {
  return offer.funded?.type === 'affiliate';
}

export function transformOfferMetadata(metadata: Offer['metadata']) {
  return metadata as Record<string, string>;
}

export function omitPropertiesFromMetadata(metadata: Offer['metadata']) {
  return omit(metadata, protectedMetadataPropsList);
}

export function metadataHasProgramInfo(
  metadata: Offer['metadata'],
): metadata is Record<string, ProgramInfo> {
  return !!metadata && 'programInfo' in metadata;
}

export function numOfAddedAndAvailablePrograms(
  offer: Offer,
  programsByCountry: State['programs']['programsByCountry'],
): string {
  return `${(offer.programsLinkStatus || []).length}/${
    programsByCountry[offer.countryCode].filter(program =>
      filterProgramByType(program, 'transaction-select'),
    ).length
  }`;
}
