import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Row, Steps } from 'antd';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { clearSubState, createOffer, updateOffer } from '../../../store/offers';
import { weekDays } from '../../../types';
import { getProfile } from '../../../store/account/account-actions';
import { Offer } from '../../../store/offers/offers-model';
import { Location } from '../../../store/locations/locations-model';
import { CreateUpdateOfferFormModel } from '../types';
import DrawerForm from '../../../components/drawer-form';
import { Title } from './styled';
import { isUniqueOffer } from '../utils';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import useSelectedRows from '../../../hooks/use-selected-rows';
import createUpdateOfferRules from './validation';
import { Scheme } from '../../../utils/schemes';
import OfferForm from './offer-form';
import LocationsForm from './locations-form';
import { queryClient } from '../../../react-query';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useOfferToFormModel } from './hooks/use-offer-to-form-model';
import { useFormModelToOffer } from './hooks/use-form-model-to-offer';
import { buildLocationsToUpdate, buildRecord } from './utils';

const steps = ['offer', 'locations'] as const;
type CreateUpdateOfferStep = typeof steps[number];

export interface CreateUpdateOfferProps extends DrawerVisibleProps {
  offer?: Offer | null;
  onClose: () => void;
}

function CreateUpdateOffer({
  offer,
  onClose,
  visible,
  afterVisibleChange,
}: CreateUpdateOfferProps) {
  const dispatch = useAppDispatch();
  const {
    account,
    isLive,
    loading,
    locationsStatus,
    createdOrUpdated,
    locationsByOffer,
    locationsByPrograms,
  } = useAppSelector(
    state => ({
      account: state.account.details,
      isLive: state.live,
      loading: state.offer.loading || state.offers.loading,
      locationsStatus: state.offer.locationsStatus,
      createdOrUpdated: state.offer.createdOrUpdated,
      locationsByOffer: state.offer.locations,
      locationsByPrograms: state.locations.locations,
    }),
    shallowEqual,
  );
  const offerLocations: Location[] = useMemo(
    () =>
      (offer?.id ? locationsByOffer[offer.id]?.items || [] : []) as Location[],
    [locationsByOffer, offer?.id],
  );

  const [step, setStep] = useState<CreateUpdateOfferStep>('offer');
  const offerToFormModel = useOfferToFormModel();
  const formModelToOffer = useFormModelToOffer();
  const { setRecord, ...selectedLocations } = useSelectedRows<Location>(
    ({ id }) => id,
  );
  const isUpdating = !!offer;
  const formModel = offer ? offerToFormModel(offer) : null;

  const { linked, unlinked } = locationsStatus ?? {};
  const isLocationsProcessing = linked?.loading || unlinked?.loading;
  const isProcessing = loading || !!isLocationsProcessing;
  const amexEnabled = account?.amex;
  const defaultSchemes: Scheme[] = ['visa', 'mastercard'];

  if (amexEnabled) defaultSchemes.push('amex');

  const isMarketplaceOffer = !!offer && isUniqueOffer(offer);

  const updatingAddedMarketplaceOffer = isUpdating && isMarketplaceOffer;

  const form = useForm<CreateUpdateOfferFormModel>({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues: {
      ...(formModel || {
        activation: false,
        selfFunded: !isLive,
        discountType: 'percentage',
        schedule: weekDays,
        schemes: defaultSchemes,
        startDate: moment(),
        maxPaymentTimeline: 30,
        qualifiedTransactionsLimit: 1,
      }),
    },
  });

  const { handleSubmit, watch, register } = form;
  const { t } = useTranslation('offers');

  const selectedProgram = watch('programsToLinkLocationsFrom');

  useEffect(() => {
    register('brandId', createUpdateOfferRules.brandId);
  }, [register]);

  useEffect(() => {
    const programLocations = selectedProgram?.length
      ? locationsByPrograms[selectedProgram[0]]?.items || []
      : [];

    const locations =
      isUpdating && offerLocations.length ? offerLocations : programLocations;

    setRecord(buildRecord(locations));
  }, [
    locationsByPrograms,
    isUpdating,
    offerLocations,
    setRecord,
    selectedProgram,
  ]);

  useEffect(() => {
    if (createdOrUpdated && step === 'offer') {
      dispatch(clearSubState('createdOrUpdated'));
    }
  }, [createdOrUpdated, step, dispatch]);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  function selectedProgramsLocations({
    programsToLinkLocationsFrom,
  }: CreateUpdateOfferFormModel) {
    return (programsToLinkLocationsFrom || []).reduce(
      (locations, programId) => [
        ...locations,
        ...(locationsByPrograms[programId]?.items || []),
      ],
      [] as Location[],
    );
  }

  function getProgramToLinkLocationsFrom(
    conditions: (string[] | undefined | boolean)[],
    { programsToLinkLocationsFrom }: CreateUpdateOfferFormModel,
  ) {
    return conditions.every(Boolean) ? programsToLinkLocationsFrom : undefined;
  }

  function handleCreateUpdate(formValues: CreateUpdateOfferFormModel) {
    const programLocations = selectedProgramsLocations(formValues);
    const { link, unlink } = buildLocationsToUpdate(
      selectedLocations.items,
      offerLocations,
    );
    const offerToSave = formModelToOffer(
      formValues,
      offer,
      isUpdating,
      isMarketplaceOffer,
    );

    if (offer?.id) {
      dispatch(updateOffer(offer.id, offerToSave, { link, unlink }));
    } else {
      const allLocationsSelected =
        selectedLocations.items.length === programLocations.length;

      dispatch(
        createOffer(
          offerToSave as Offer,
          link,
          getProgramToLinkLocationsFrom(
            [allLocationsSelected, !!programLocations.length],
            formValues,
          ),
        ),
      );
    }

    queryClient.invalidateQueries(['offers']);
  }

  function onSubmit() {
    if (step === 'offer' && !updatingAddedMarketplaceOffer) {
      handleSubmit(() => setStep('locations'))();
    } else handleSubmit(handleCreateUpdate)();
  }

  const header = () => (
    <Title>
      <span>{t(`createUpdate.title.${offer ? 'edit' : 'create'}`)}</span>
      {!updatingAddedMarketplaceOffer && (
        <Steps
          current={steps.indexOf(step)}
          onChange={current => setStep(steps[current])}
          size="small"
        >
          <Steps.Step title={t('createUpdate.step.details')} />
          <Steps.Step title={t('createUpdate.step.locations')} disabled />
        </Steps>
      )}
    </Title>
  );

  function confirmText() {
    if (step === 'offer' && !updatingAddedMarketplaceOffer) {
      return (
        <Row align="middle">
          <span>{t(`createUpdate.confirmText.next`)}</span>
          <RightOutlined style={{ fontSize: '8px', marginLeft: '14px' }} />
        </Row>
      );
    }

    return undefined;
  }

  return (
    <DrawerForm
      title={header()}
      confirmText={confirmText()}
      onBack={step === 'locations' ? () => setStep('offer') : undefined}
      onClose={onClose}
      onSubmit={onSubmit}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
      submitDisabled={isProcessing}
      processing={isProcessing}
      hasFinished={createdOrUpdated}
      push={{ distance: 8 }}
    >
      <FormProvider {...form}>
        <OfferForm
          formModel={formModel}
          isUpdating={isUpdating}
          isMarketplaceOffer={isMarketplaceOffer}
          visible={step === 'offer'}
        />
        {step === 'locations' && (
          <LocationsForm
            offer={{
              ...offer,
              ...formModelToOffer(
                watch() as CreateUpdateOfferFormModel,
                offer,
                isUpdating,
              ),
            }}
            offerLocations={offerLocations}
            selectedLocations={selectedLocations}
          />
        )}
      </FormProvider>
    </DrawerForm>
  );
}

export default drawerTransition(CreateUpdateOffer);
