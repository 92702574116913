import { FiltersProps } from '../../../components/filters';
import { FilterName } from '../../../components/filters/components/FilterDropdown';
import { OfferType } from '../../../store/offers/offers-model';
import { OfferHeading, columnsByTab, filtersByTab } from './config';

export function getTableContentByTab<T>(
  offerType: OfferType,
  object: Record<OfferHeading, T>,
): T[] {
  return columnsByTab[offerType].map(record => object[record]);
}

export function getFilters(
  tab: OfferType,
  isContentProvider = false,
): FiltersProps {
  const filtersConfig = { ...filtersByTab[tab] };

  if (!isContentProvider) return filtersConfig;

  const hiddenFilters: FilterName[] = ['countryCode', 'contentProviderId'];

  return {
    defaultFilters: filtersConfig.defaultFilters.filter(
      name => !hiddenFilters.includes(name),
    ),
    moreFilters: filtersConfig.moreFilters?.filter(
      name => !hiddenFilters.includes(name),
    ),
  };
}
