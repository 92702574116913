import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Severity } from '@sentry/browser';

import en from './locales/en';

const keysSentToSentry = new Set();

export const defaultNS = 'common';
export const resources = {
  en,
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    ns: Object.keys(resources.en),
    defaultNS,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler(key) {
      const message = `Missing translation key: ${key} (${i18n.language})`;
      // eslint-disable-next-line no-console
      console.warn(message);

      if (!keysSentToSentry.has(key)) {
        Sentry.captureMessage(message, Severity.Warning);
        keysSentToSentry.add(key);
      }

      return key;
    },
  });

export default i18n;
