import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Input } from 'antd';

import { updateAccount } from '../../../../store/account/account-actions';
import InlineForm from '../../../../components/inline-form';
import { colors } from '../../../../theme';
import { Select } from '../../../../components/select';
import { ProgramType } from '../../../../store/programs/programs-reducer';
import { Initials } from './billing-periods-tables/styled';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface ProgramDetailsForm {
  programType: ProgramType;
  vopCommunityCode: string;
  vopPromoCode: string;
}

interface ProgramTypeSelectOption {
  type: ProgramType;
  color: string;
  initials: 'ST' | 'TS';
}

const programTypes: ProgramTypeSelectOption[] = [
  {
    type: 'transaction-select',
    color: colors.product.selectTransaction,
    initials: 'ST',
  },
  {
    type: 'transaction-stream',
    color: colors.product.transactionStream,
    initials: 'TS',
  },
];

const vopCommunityCodes = [
  'FIDELEXPENSEUS',
  'FIDELEXPENSECA',
  'FIDELEXPENSEEU',
];

const ProgramDetails = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'account.contract.programDetails.form',
  });
  const { t: tPrograms } = useTranslation('programs');
  const dispatch = useAppDispatch();
  const { accountDetails, loading, error } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      loading: state.account.loading,
      error: state.account.error,
    }),
    shallowEqual,
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ProgramDetailsForm>({
    shouldUnregister: true,
    mode: 'onBlur',
    defaultValues: {
      programType: accountDetails?.products?.transactionStream
        ? 'transaction-stream'
        : 'transaction-select',
      vopCommunityCode: accountDetails?.visaConfig?.vopCommunityCode,
      vopPromoCode: accountDetails?.visaConfig?.vopPromoCode,
    },
  });

  const currentProgramType = watch('programType');

  const isTransactionStreamSelected =
    currentProgramType === 'transaction-stream';

  function onSubmit({
    programType,
    vopCommunityCode,
    vopPromoCode,
  }: ProgramDetailsForm) {
    const payload = isTransactionStreamSelected
      ? { programType, visaConfig: { vopCommunityCode, vopPromoCode } }
      : {
          programType,
        };

    dispatch(updateAccount(payload));
  }

  return (
    <InlineForm
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      error={error}
    >
      <InlineForm.ItemController
        label={tPrograms('programType.title')}
        errors={errors}
        controller={{
          name: 'programType',
          render: ({ field }) => (
            <Select {...field} defaultValue={programTypes[0].type}>
              {programTypes.map(({ type, color, initials }, idx) => (
                <Select.Option key={idx} value={type}>
                  <Initials color={color}>{initials}</Initials>
                  {tPrograms(`programType.${type}.label`)}
                </Select.Option>
              ))}
            </Select>
          ),
          rules: { required: true },
          control,
        }}
      />
      {isTransactionStreamSelected && (
        <>
          <InlineForm.ItemController
            label={t('vopCommunityCode')}
            errors={errors}
            controller={{
              name: 'vopCommunityCode',
              render: ({ field }) => (
                <Select
                  {...field}
                  placeholder={t('placeholders.vopCommunityCode')}
                >
                  {vopCommunityCodes.map((vopCommunityCode, idx) => (
                    <Select.Option key={idx} value={vopCommunityCode}>
                      {vopCommunityCode}
                    </Select.Option>
                  ))}
                </Select>
              ),
              rules: { required: true },
              control,
            }}
          />
          <InlineForm.ItemController
            label={t('vopPromoCode')}
            errors={errors}
            controller={{
              name: 'vopPromoCode',
              render: ({ field }) => (
                <Input
                  placeholder={t('placeholders.vopPromoCode')}
                  {...field}
                />
              ),
              rules: { required: true },
              control,
            }}
          />
        </>
      )}
    </InlineForm>
  );
};

export default ProgramDetails;
