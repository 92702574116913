import { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';

import { Offer, OfferType } from '../../../../store/offers/offers-model';
import { Action } from '../../../../components/actions-menu';
import { isUniqueOffer } from '../../utils';
import { updateOffer } from '../../../../store/offers';
import useCopyWithNotification from '../../../../hooks/use-copy-with-notification';
import useRawDetail from '../../../../components/raw-detail/hooks/use-raw-detail';
import useRedirect from '../../../../hooks/use-redirect';
import { OfferActions, OnSelectProps } from '../../types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export interface ActionProps extends Action {
  showInDetail?: true;
}

export default function useActions(
  offerType: OfferType,
  onSelect: OnSelectProps,
) {
  const { t } = useTranslation(['common', 'offers']);
  const dispatch = useAppDispatch();
  const redirect = useRedirect();
  const location = useLocation();
  const { isLive, loading, isPublisher } = useAppSelector(
    state => ({
      isLive: state.live,
      isPublisher: !state.account.contentProviderInfo,
      loading: state.offers.loading || state.offer.loading,
    }),
    shallowEqual,
  );
  const [selectedOffer, setSelectedOffer] = useState<Offer | undefined>();

  const copyWithNotification = useCopyWithNotification();
  const { rawDetail, openRawDetail } = useRawDetail('offers');

  useEffect(() => {
    setSelectedOffer(undefined);
  }, [offerType, isLive]);

  useEffect(() => {
    const { offer } = (location.state || {}) as { offer: Offer };

    if (offer) setSelectedOffer(offer);
  }, [location.state]);

  const isRequestedOffers = offerType === 'requests';

  const startActionShouldBeDisabled = [
    isRequestedOffers,
    offerType === 'live',
  ].some(Boolean);

  const stopActionShouldBeDisabled = [
    isRequestedOffers,
    offerType === 'expired',
    offerType === 'upcoming',
  ].some(Boolean);

  function stopOrStart(action: 'stop' | 'start', { id, name }: Offer) {
    Modal.confirm({
      title: t(`offers:${action}.title`),
      content: t(`offers:${action}.message`, { name }),
      onOk: () => {
        id &&
          dispatch(
            updateOffer(id, {
              ...(action === 'start' && {
                startDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
              }),
              endDate:
                action === 'stop'
                  ? moment().format('YYYY-MM-DD[T]HH:mm:ss')
                  : null,
            }),
          );
        redirect(`/offers/${action === 'stop' ? 'expired' : 'live'}`);
      },
      okButtonProps: { danger: true, disabled: loading },
      okText: t(`offers:actions.${action}`),
      maskClosable: true,
      icon: null,
    });
  }

  function rawDetailEndpoint(offer: Offer) {
    if (offerType === 'marketplace') return 'unique-offers';

    if (
      offerType === 'awaitingApproval' ||
      (offerType === 'requests' && offer.isAccountProgramUniqueOffer)
    )
      return `programs/${offer.programId}/unique-offers`;

    return 'offers';
  }

  function getActions(offer: Offer): ActionProps[] {
    const { id, publisherInfo } = offer;

    const isMarketplaceOffer = isUniqueOffer(offer);

    const isEditable =
      offerType !== 'marketplace' &&
      offerType !== 'expired' &&
      offerType !== 'awaitingApproval';

    return [
      {
        label: t('common:actions.viewRaw'),
        callback: () => id && openRawDetail(id, rawDetailEndpoint(offer))(),
      },
      {
        label: t('common:actions.copyId'),
        callback: () => id && copyWithNotification(id),
      },
      {
        label: t('offers:actions.contactPublisher'),
        hidden: offerType !== 'awaitingApproval',
        callback: () => {
          window.open(`mailto:${publisherInfo?.userEmail}`);
        },
      },
      {
        label: t('offers:actions.edit'),
        callback: () => {
          onSelect.set.action(OfferActions.EDIT);
          onSelect.set.offer(offer);
        },
        hidden: !isEditable,
        showInDetail: true,
      },
      {
        label: t(
          `offers:actions.${id === selectedOffer?.id ? 'hide' : 'view'}Detail`,
        ),
        callback: () =>
          !isEqual(selectedOffer, offer)
            ? setSelectedOffer(offer)
            : setSelectedOffer(undefined),
      },
      {
        label: t('offers:actions.start'),
        callback: () => stopOrStart('start', offer),
        disabled: startActionShouldBeDisabled,
        hidden: isMarketplaceOffer,
      },
      {
        label: t('offers:actions.stop'),
        callback: () => stopOrStart('stop', offer),
        disabled: stopActionShouldBeDisabled,
        hidden: !(isPublisher && !isMarketplaceOffer),
      },
    ];
  }

  return {
    rowActions: (offer: Offer) => getActions(offer),
    rawDetail,
    detailActions: (offer: Offer) =>
      getActions(offer).filter(
        ({ showInDetail, hidden }) => showInDetail && !hidden,
      ),
    selectedOffer,
    setSelectedOffer,
  };
}
