import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space } from 'antd';

import {
  filterLocations,
  clearLocations,
} from '../../store/filters/filters-actions';
import * as LocationActions from '../../store/locations/locations-actions';
import { Location } from '../../store/locations/locations-model';
import * as ProgramActions from '../../store/programs/programs-actions';
import { getVirtualCards } from '../../store/account/account-actions';
import {
  CardSchemeStatus,
  LinkLocation,
  LinkVirtualCardTransaction,
  LinkToOfferDropdown,
  NoLocations,
  SyncLocations,
  SubmitMerchantIds,
} from './components';
import UniqueBrandsLocations from '../brands/components/create-update-brand';
import { Created } from './styled';
import ConditionalTooltip from '../../components/conditional-tooltip';
import FilterBox from '../../components/filter-box';
import Header from '../../components/header';
import InfiniteScrollTable from '../../components/tables/InfiniteScrollTable';
import { Column } from '../../components/tables/BaseTable';
import SelectionIndicator from '../../components/selection-indicator';
import VirtualCardsModal from '../account/components/virtual-cards/VirtualCardsModal';
import Ellipsis from '../../components/ellipsis';
import useRawDetail from '../../components/raw-detail/hooks/use-raw-detail';
import { GreyDropdownButton } from '../../components/buttons';
import useShowSyncHelpState from './show-sync-help';
import usePrevious from '../../hooks/use-previous';
import useProgramLocationsStatuses from '../../hooks/use-program-locations-statuses';
import useSelectedProgram from '../../hooks/use-selected-program';
import useSelectedRows from '../../hooks/use-selected-rows';
import useCopyWithNotification from '../../hooks/use-copy-with-notification';
import { ReactComponent as Visa } from '../../assets/visa_icon.svg';
import { ReactComponent as MasterCard } from '../../assets/mastercard_icon.svg';
import { ReactComponent as Amex } from '../../assets/amex_icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import memoNoProps from '../../utils/memo-no-props';
import schemes, { Scheme } from '../../utils/schemes';
import { buildAddress } from '../../utils/transform';
import { DOUBLE_MINUS } from '../../utils/special-character';
import useIsSignedIn from '../../hooks/use-is-signed-in';
import { FormSteps } from '../brands/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import LocationStatusHelper from './components/LocationStatusHelper';

type SchemesKeys = keyof Pick<Location, 'amex' | 'mastercard' | 'visa'>;

function isLocationSyncing(location: Location) {
  return (['mastercard', 'amex', 'visa'] as SchemesKeys[]).some(
    scheme => location[scheme] && location[scheme]?.status === 'sync',
  );
}

const Locations = () => {
  const { t } = useTranslation(['common', 'locations']);
  const dispatch = useAppDispatch();
  const { selectedProgram } = useSelectedProgram();
  const programId = selectedProgram?.id;
  const programIsSyncing = selectedProgram?.sync;
  const previousProgramId = usePrevious(programId);
  const copyWithNotification = useCopyWithNotification();
  const { isAccountAdmin } = useIsSignedIn();
  const [
    [locationForSubmitMerchantId],
    setLocationAndSchemeForSubmitMerchantId,
  ] = useState<[Location?, Scheme?]>([]);
  const [
    locationForLinkVirtualCardTransaction,
    setLocationForLinkVirtualCardTransaction,
  ] = useState<Location | null>(null);
  const [showCreateUpdateLocation, setShowCreateUpdateLocation] =
    useState(false);
  const [syncLocationsEnabled, setSyncLocationsEnabled] = useState(false);
  const [showSyncLocations, setShowSyncLocations] = useState(false);
  const [showLocationStatusHelper, setShowLocationStatusHelper] =
    useState(false);
  const [showSyncHelp] = useShowSyncHelpState();
  const [showVirtualCardsModal, setShowVirtualCardsModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{
    mode: 'edit' | 'link';
    location: Location;
  }>();
  const { rawDetail, openRawDetail } = useRawDetail('locations');
  const {
    accountDetails,
    live,
    locations,
    loading,
    canLoadMore,
    total,
    filters,
    activeFilters,
    virtualCards,
  } = useAppSelector(state => {
    const programLocations = state.locations.locations[programId];
    if (!programLocations) return {};

    return {
      accountDetails: state.account.details,
      addingPaymentMethod: state.account.paymentMethods.adding,
      live: state.live,
      locations: programLocations.items,
      loading: programLocations.loading,
      canLoadMore: programLocations.last,
      total: state.locations.counts[programId]?.count,
      filters: state.filters.locations,
      activeFilters: state.filters.activeFilters.locations,
      virtualCards: state.account.virtualCards.entities,
    };
  }, shallowEqual);
  const selectedRows = useSelectedRows<any>(location => location.id, locations);
  const { hasIdleLocations } = useProgramLocationsStatuses(programId);

  const { liveActive } = accountDetails || {};

  const { current: fetchedIds } = useRef(new Set<string | undefined>());

  useEffect(() => {
    if (previousProgramId !== programId) {
      selectedRows.clear();
      fetchedIds.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousProgramId, programId]);

  useEffect(() => {
    if (programId) {
      dispatch(ProgramActions.getProgram(programId));
      dispatch(LocationActions.getLocations(programId));
      fetchedIds.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, programId]);

  useEffect(() => {
    if (live && isAccountAdmin) dispatch(getVirtualCards());
  }, [dispatch, isAccountAdmin, live]);

  useEffect(() => {
    let intervalToken: number;
    if (programIsSyncing) {
      intervalToken = window.setInterval(() => {
        if (programId) {
          dispatch(ProgramActions.getProgram(programId));
        }
      }, 10000);
    }
    return () => clearInterval(intervalToken);
  }, [dispatch, programId, programIsSyncing]);

  useEffect(() => {
    setSyncLocationsEnabled(!programIsSyncing);
  }, [programIsSyncing]);

  useEffect(() => {
    if (locations?.length === 0 && canLoadMore) fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadMore?.id, dispatch, programId]);

  function fetchLocations() {
    const currentID = canLoadMore?.id;
    if (!fetchedIds.has(currentID)) {
      dispatch(
        locations?.length === 0
          ? LocationActions.getLocations(programId, canLoadMore)
          : LocationActions.getMoreLocations(programId),
      );
      fetchedIds.add(currentID);
    }
  }

  const columns: Column[] = [
    { heading: t('locations:address') },
    { heading: t('locations:brand'), size: 0.75 },
    { heading: <Visa />, size: 0.5 },
    { heading: <MasterCard />, size: 0.5 },
    { heading: <Amex />, size: 0.5 },
    { heading: t('locations:created'), sortedBy: 'unset' },
  ];

  const rows = (locations || []).map((location: Location, index: number) => ({
    selected: selectedRows.isSelected(location),
    onSelect: selectedRows.onSelect(location),
    onClick: openRawDetail(location.id),
    contents: [
      <Ellipsis>{buildAddress(location, false)}</Ellipsis>,
      <Ellipsis>{location.brandId.name || DOUBLE_MINUS}</Ellipsis>,
      ...schemes.map(scheme => (
        <CardSchemeStatus
          key={scheme}
          location={location}
          scheme={scheme}
          onSubmitMerchantId={() =>
            setLocationAndSchemeForSubmitMerchantId([location, scheme])
          }
          onLinkVirtualCardTransaction={() =>
            virtualCards?.length
              ? setLocationForLinkVirtualCardTransaction(location)
              : setShowVirtualCardsModal(true)
          }
          data-onboarding-target={`location-${index}-${scheme}`}
        />
      )),
      <Created title={location.created}>
        {moment(location.created).fromNow()}
      </Created>,
    ],
    actions: [
      {
        label: t('common:actions.viewRaw'),
        callback: openRawDetail(location.id),
      },
      {
        label: t('common:actions.copyId'),
        callback: () => copyWithNotification(location.id),
      },
      {
        label: t('locations:actions.edit'),
        callback: () => {
          fetchedIds.clear();
          setSelectedLocation({ location, mode: 'edit' });
        },
        disabled: isLocationSyncing(location),
      },
      {
        label: t('locations:actions.delete'),
        callback: () =>
          Modal.confirm({
            title: t('locations:deleteLocation.title'),
            content: t('locations:deleteLocation.message'),
            onOk: () => {
              fetchedIds.clear();
              dispatch(LocationActions.deleteLocation(programId, location.id));
            },
            okButtonProps: { danger: true },
            okText: t('common:confirm.delete'),
            maskClosable: true,
            icon: null,
          }),
      },
      {
        label: t('locations:actions.linkToOffer'),
        callback: () => setSelectedLocation({ location, mode: 'link' }),
      },
    ],
  }));

  function deleteSelected() {
    Modal.confirm({
      title: t('locations:deleteLocation.title'),
      content: t('locations:deleteLocation.message'),
      onOk: () => {
        fetchedIds.clear();
        dispatch(
          LocationActions.deleteLocation(
            selectedProgram.id,
            selectedRows.items,
          ),
        );
        selectedRows.clear();
      },
      okButtonProps: { danger: true },
      okText: t('common:confirm.delete'),
      maskClosable: true,
      icon: null,
    });
  }

  const topContent = (
    <>
      <Header
        heading={selectedProgram?.name}
        bottomLeft={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FilterBox
              filterName="locations"
              action={filterLocations}
              clear={clearLocations}
            />
            {!loading && (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  setShowLocationStatusHelper(true);
                }}
                data-testid="locations-status-helper"
                style={{ marginLeft: '8px' }}
              >
                {t('locations:locationsHelper.linkButton')}
              </Button>
            )}
          </div>
        }
        topRight={
          <div style={{ display: 'flex' }}>
            <Button
              type="primary"
              size="small"
              onClick={() => setShowCreateUpdateLocation(true)}
              data-testid="add-location"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PlusIcon style={{ marginRight: '3px' }} />
                <span style={{ paddingTop: '2px' }}>
                  {t('common:addNew.location')}
                </span>
              </div>
            </Button>
            {live && hasIdleLocations && liveActive && (
              <ConditionalTooltip
                condition={!syncLocationsEnabled}
                placement="bottom"
                title={t('locations:syncLocationsDisabled')}
              >
                <Button
                  type="link"
                  size="small"
                  loading={!syncLocationsEnabled}
                  onClick={() => {
                    if (showSyncHelp) {
                      setShowSyncLocations(true);
                    } else {
                      dispatch(ProgramActions.syncProgram(programId));
                    }
                  }}
                  data-testid="sync-locations"
                  style={{ marginLeft: '8px' }}
                >
                  {t('locations:syncLocations.title')}
                </Button>
              </ConditionalTooltip>
            )}
          </div>
        }
      />
      <SelectionIndicator
        count={selectedRows.count}
        i18nKey="selected"
        ns="locations"
        actions={
          <Space size={filters?.brandId ? 8 : 0}>
            <GreyDropdownButton onClick={deleteSelected}>
              {t('locations:deleteSelected')}
            </GreyDropdownButton>
            <LinkToOfferDropdown
              brandId={filters?.brandId}
              locations={selectedRows.items}
            />
          </Space>
        }
      />
    </>
  );

  return (
    <>
      <InfiniteScrollTable
        columns={columns}
        rows={rows}
        onSelectAll={selectedRows.onSelectAll(locations || [])}
        activeFilters={activeFilters}
        total={total}
        emptyText={
          <NoLocations
            showCreateUpdateLocation={() => setShowCreateUpdateLocation(true)}
          />
        }
        onBottom={() => {
          if (!loading && canLoadMore) fetchLocations();
        }}
        onReload={() => {
          fetchedIds.clear();
          !loading && dispatch(LocationActions.getLocations(programId));
        }}
        loading={loading}
        topContent={topContent}
      />
      <UniqueBrandsLocations
        visible={showCreateUpdateLocation}
        program={selectedProgram}
        brand={null}
        uniqueBrand={null}
        handleClose={() => setShowCreateUpdateLocation(false)}
        defaultStep={FormSteps?.SELECT_BRAND}
      />
      <SyncLocations
        programId={programId}
        visible={showSyncLocations}
        onClose={() => setShowSyncLocations(false)}
      />
      <LocationStatusHelper
        visible={showLocationStatusHelper}
        onClose={() => setShowLocationStatusHelper(false)}
      />
      {selectedLocation && selectedLocation.mode === 'link' && (
        <LinkLocation
          location={selectedLocation.location}
          onClose={() => setSelectedLocation(undefined)}
        />
      )}
      <UniqueBrandsLocations
        visible={selectedLocation?.mode === 'edit'}
        program={selectedProgram}
        location={selectedLocation?.location}
        brand={null}
        uniqueBrand={null}
        handleClose={() => setSelectedLocation(undefined)}
        defaultStep={FormSteps?.EDIT_LOCATION}
      />
      {locationForLinkVirtualCardTransaction && (
        <LinkVirtualCardTransaction
          location={locationForLinkVirtualCardTransaction}
          visible={!!locationForLinkVirtualCardTransaction}
          onClose={() => setLocationForLinkVirtualCardTransaction(null)}
        />
      )}
      <SubmitMerchantIds
        visible={!!locationForSubmitMerchantId}
        programId={programId}
        location={locationForSubmitMerchantId}
        onClose={() => {
          setLocationAndSchemeForSubmitMerchantId([]);
        }}
      />
      <VirtualCardsModal
        showModal={showVirtualCardsModal}
        setShowModal={setShowVirtualCardsModal}
      />
      {rawDetail}
    </>
  );
};

export default memoNoProps(Locations);
