import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { app, sentry } from '../config';
import useIsSignedIn from './use-is-signed-in';
import { useAppSelector } from '../store/hooks';

Sentry.init({
  environment: sentry.ENV,
  release: `${app.NAME}-${app.VERSION}`,
  dsn: sentry.DSN,
  debug: true,
  whitelistUrls: [/dashboard\.fidel\.uk/, /dev-dash\.fidel\.uk/],
});

export default function useSetupSentry() {
  const { isModerator } = useIsSignedIn();
  const userDetails = useAppSelector(state => state.user.details);

  useEffect(() => {
    Sentry.configureScope(scope => {
      if (userDetails) {
        const { email, nameFirst, nameLast } = userDetails;
        scope.setUser({
          email,
          username: `${nameFirst} ${nameLast}`,
          id: email,
          isModerator,
        });
        scope.setTag('fidel-lang', localStorage.getItem('fidel-lang') || 'en');
      }
    });
  }, [isModerator, userDetails]);
}
