import moment from 'moment';

import { Card } from '../store/cards/cards-types';
import { Location } from '../store/locations/locations-model';
import { transformToTransaction } from '../store/transactions/transactions-model';

import { allCountries } from './countries';
import regex from './regex';
import { dateFormat, toDate } from './dates';

export function capitalize(word: string | undefined) {
  if (!word) return '';

  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const symbols: any = {
  EUR: '€',
  GBP: '£',
  CAD: '$',
  USD: '$',
  JPY: '¥',
  SEK: 'kr',
};

export const toCountryName = (codeInput: string) => {
  const code = codeInput.toUpperCase();
  const country = allCountries.find(c => c.code === code || c.code2 === code);
  if (country) {
    return country.name;
  }
  throw new Error(`Country name not found for code ${code}.`);
};

function numberWithCommas(value: string) {
  const parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const toDecimal = (num: number) => numberWithCommas(num.toFixed(2));

export const withCurrency = (
  amount: number | undefined,
  currencyInput: string | undefined,
  showDecimals = true,
  showCurrencyAs: 'symbol' | 'code' = 'symbol',
) => {
  const finalAmount = showDecimals
    ? toDecimal(amount || 0)
    : amount?.toLocaleString() || 0;

  if (!currencyInput) return finalAmount;

  const currencyCode = currencyInput?.toUpperCase();
  const currencySymbol = symbols[currencyCode] || currencyCode || '';

  return showCurrencyAs === 'code'
    ? `${finalAmount} ${currencyCode}`
    : `${currencySymbol} ${finalAmount}`;
};

export const toExportCardsData = (
  cards: Card[],
  { formatted }: any = { formatted: true },
) =>
  cards.map(({ id, lastNumbers, expDate, scheme, created }) => ({
    id,
    lastNumbers: formatted ? `••••${lastNumbers}` : lastNumbers,
    expDate: moment.utc(expDate).format('MMM - YYYY'),
    scheme: capitalize(scheme),
    created: toDate(created),
  }));

export const toExportTransactionsData = (
  transactions: any[],
  { formatted, includeCurrency }: any = {
    formatted: true,
    includeCurrency: false,
  },
) =>
  transactions
    .map(transformToTransaction)
    .map(({ id, address, cardId, status, ...transaction }) => ({
      id,
      amount: formatted
        ? withCurrency(transaction.amount, transaction.currency)
        : transaction.amount,
      address,
      brand: transaction.brand.name,
      cardId,
      card: `${formatted ? '••••' : ''}${transaction.lastNumbers}`,
      date: moment(transaction.date).format(dateFormat),
      scheme: capitalize(transaction.scheme),
      status,
      ...(includeCurrency
        ? { currency: transaction.currency.toUpperCase() }
        : {}),
    }));

export function buildAddress(
  location?: Partial<Location>,
  includeCountry = true,
) {
  const parts = [location?.address, location?.city, location?.postcode];

  if (includeCountry) {
    const country = allCountries.find(
      ({ code }) => code === location?.countryCode,
    );
    const countryCode = (country && country.code2) || location?.countryCode;
    parts.push(countryCode);
  }

  return parts.filter(part => part).join(', ');
}

export function hexToRgb(hexColor: string) {
  const result = regex.hex.exec(hexColor);
  return result
    ? {
        r: parseInt(result[2], 16),
        g: parseInt(result[3], 16),
        b: parseInt(result[4], 16),
      }
    : null;
}

export const arrayFromNumber = (number: number, offset = 0) =>
  Array.from(Array(number), (_, idx) => idx + offset);

export const sortArrayByProperty = (objArray: any, property: string) =>
  objArray.sort((a: any, b: any) => a[property].localeCompare(b[property]));

export const sortObjectAlphabetically = (obj: any) =>
  Object.keys(obj)
    .sort()
    .reduce(
      (result, key) => ({
        ...result,
        [key]: obj[key],
      }),
      {},
    );

export function objectKeys<Obj extends object>(obj: Obj): (keyof Obj)[] {
  return Object.keys(obj) as (keyof Obj)[];
}
