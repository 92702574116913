import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { selectProgramsAsList } from '../../../store/programs/programs-selectors';
import { resetNewlyAddedUniqueOffer } from '../../../store/offers';
import memoNoProps from '../../../utils/memo-no-props';
import ProgramDropdown from './program-dropdown';
import { NavItem } from './index';
import {
  Caron,
  GoBackButton,
  GoBackButtonContainer,
  Menu,
  MenuContainer,
  MenuItem,
  SubMenu,
  SubMenuTitle,
  NavItemHighlighter,
} from '../styled';
import { ReactComponent as AccountsIcon } from '../assets/accounts.svg';
import { ReactComponent as BrandsIcon } from '../assets/brands.svg';
import { ReactComponent as CardsIcon } from '../assets/cards.svg';
import { ReactComponent as LocationsIcon } from '../assets/locations.svg';
import { ReactComponent as OffersIcon } from '../assets/offers.svg';
import { ReactComponent as OffersMarketplaceIcon } from '../assets/offers_marketplace.svg';
import { ReactComponent as OffersRequestsIcon } from '../assets/offers_requests.svg';
import { ReactComponent as OffersUpcomingIcon } from '../assets/offers_upcoming.svg';
import { ReactComponent as OffersAwaitingApprovalsIcon } from '../assets/offers_awaitingApprovals.svg';
import { ReactComponent as OffersLiveIcon } from '../assets/offers_live.svg';
import { ReactComponent as OffersExpiredIcon } from '../assets/offers_expired.svg';
import { ReactComponent as PlaygroundIcon } from '../assets/playground.svg';
import { ReactComponent as ProgramsIcon } from '../assets/programs.svg';
import { ReactComponent as TransactionsIcon } from '../assets/transactions.svg';
import { ReactComponent as WebhooksIcon } from '../assets/webhooks.svg';
import useIsSignedIn from '../../../hooks/use-is-signed-in';
import useHasOffersApi from '../../../hooks/use-has-offers-api';
import useRedirect from '../../../hooks/use-redirect';
import useTransactionStream from '../../../hooks/use-transaction-stream';
import { useSetStatus } from '../../../hooks/use-status';
import useProgressState from '../../../hooks/use-progress-state';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useOffersCount } from '../../../modules/offers/api/useOffers';

const Navigation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isLive,
    newlyAddedUniqueOffer,
    hasMarketplaceAccess,
    hasAccount,
    isContentProvider,
  } = useAppSelector(
    state => ({
      isLive: state.live,
      hasMarketplaceAccess: state.account.marketplace.hasMarketplaceAccess,
      hasAccount: !!state.account.details,
      newlyAddedUniqueOffer: state.offer.addedUniqueOffers.new,
      isContentProvider: !!state.account.contentProviderInfo,
    }),
    shallowEqual,
  );
  const { isModerator } = useIsSignedIn();
  const [submenu, setSubmenu] = useState('');
  const { pathname } = useLocation();
  const redirect = useRedirect();
  const hasOffersApi = useHasOffersApi();
  const { setStatus } = useSetStatus();
  const programs = useAppSelector(selectProgramsAsList)();
  const programsLoading = useProgressState(
    useAppSelector(state => state.programs.loading),
  );
  const { hasAccountTransactionStream, hasProgramTransactionStream } =
    useTransactionStream();
  const { data: offersCount } = useOffersCount();

  const programNavItems: NavItem[] = useMemo(
    () => [
      {
        path: 'locations',
        visible: hasAccount && !hasProgramTransactionStream,
        icon: <LocationsIcon />,
      },
      { path: 'cards', visible: hasAccount, icon: <CardsIcon /> },
      {
        path: 'transactions',
        visible: hasAccount,
        icon: <TransactionsIcon />,
      },
      { path: 'webhooks', visible: hasAccount, icon: <WebhooksIcon /> },
    ],
    [hasAccount, hasProgramTransactionStream],
  );

  const programPaths = programNavItems.map(item => item.path as string);
  programsLoading.onFinish(() => {
    if (
      programs.length === 0 &&
      programPaths.some(path => pathname.includes(path))
    ) {
      redirect('/programs');
    }
  });

  const navItems: NavItem[] = useMemo(
    () => [
      {
        path: 'accounts',
        visible: isModerator,
        icon: <AccountsIcon />,
      },
      {
        path: 'programs',
        visible: hasAccount,
        icon: <ProgramsIcon />,
      },
      {
        path: 'brands',
        visible: hasAccount && !hasAccountTransactionStream,
        icon: <BrandsIcon />,
      },
      {
        section: 'programSelected',
        title: <ProgramDropdown />,
        items: programNavItems,
      },
      {
        name: 'offers',
        path: hasMarketplaceAccess ? 'offers/marketplace' : 'offers/requests',
        visible: hasAccount && hasOffersApi && !hasAccountTransactionStream,
        icon: <OffersIcon data-testid="offers-icon" />,
        onClick: () => setSubmenu('offers'),
      },
      {
        name: 'offers',
        visible: hasAccount && !hasOffersApi && !hasAccountTransactionStream,
        icon: <OffersIcon data-testid="offers-icon" />,
        onClick: () => setStatus('offerApiUnavailable'),
      },
      {
        section: 'offers',
        title: <SubMenuTitle>{t('nav.offers')}</SubMenuTitle>,
        items: [
          {
            path: 'offers/marketplace',
            visible: hasAccount && hasMarketplaceAccess,
            name: 'marketplace',
            count: offersCount?.marketplace.count,
            icon: <OffersMarketplaceIcon />,
          },
          {
            path: 'offers/requests',
            visible: hasAccount,
            name: 'requests',
            count: offersCount?.requests.count,
            isHighlighted: !!newlyAddedUniqueOffer,
            icon: <OffersRequestsIcon />,
          },
          {
            path: 'offers/awaitingApproval',
            visible: hasAccount && isContentProvider,
            name: 'awaitingApproval',
            count: offersCount?.awaitingApproval.count,
            icon: <OffersAwaitingApprovalsIcon />,
          },
          {
            path: 'offers/upcoming',
            visible: hasAccount,
            name: 'upcoming',
            count: offersCount?.upcoming.count,
            icon: <OffersUpcomingIcon />,
          },
          {
            path: 'offers/live',
            visible: hasAccount,
            name: 'live',
            count: offersCount?.live.count,
            icon: <OffersLiveIcon />,
          },
          {
            path: 'offers/expired',
            visible: hasAccount,
            name: 'expired',
            count: offersCount?.expired.count,
            icon: <OffersExpiredIcon />,
          },
        ],
      },
      {
        path: 'playground',
        visible: hasAccount && !isLive,
        icon: <PlaygroundIcon />,
      },
    ],
    [
      offersCount,
      hasAccount,
      hasOffersApi,
      isLive,
      isModerator,
      isContentProvider,
      programNavItems,
      setStatus,
      newlyAddedUniqueOffer,
      t,
      hasAccountTransactionStream,
      hasMarketplaceAccess,
    ],
  );

  function displayMenuItem(
    { name, path, count, onClick, icon, isHighlighted }: NavItem,
    visible: boolean,
  ) {
    return (
      <MenuItem
        onClick={onClick}
        count={count}
        visible={visible}
        key={path || name}
        data-onboarding-target={`menu-item-${path}`}
      >
        {icon}
        {path && (
          <NavLink to={`/${path}`}>
            <span>{t(`nav.${name || path}` as any)}</span>
            {isHighlighted && <NavItemHighlighter />}
          </NavLink>
        )}
        {!path && <span>{t(`nav.${name || path}` as any)}</span>}
      </MenuItem>
    );
  }

  const selectHighlighted = useCallback(
    (items: NavItem[], initialList: NavItem[] = []): NavItem[] =>
      Object.values(items).reduce((list, item) => {
        if (item.items) return selectHighlighted(item.items, list);
        if (item.isHighlighted) {
          return [...list, item];
        }
        return list;
      }, initialList),
    [],
  );

  useEffect(() => {
    if (!pathname || pathname === '/') {
      setSubmenu('');
    } else if (
      !['playground', 'offer', 'programs', 'account', 'brands'].some(path =>
        pathname.includes(path),
      )
    ) {
      setSubmenu('programSelected');
    } else if (pathname.includes('offer')) {
      setSubmenu('offers');
    } else if (['programs', 'account'].some(path => pathname.includes(path))) {
      setSubmenu('');
    }
    if (
      selectHighlighted(navItems).find(
        ({ path }) => path && pathname.includes(path),
      )
    ) {
      dispatch(resetNewlyAddedUniqueOffer());
    }
  }, [pathname, navItems, selectHighlighted, dispatch]);

  useEffect(() => {
    if (!hasAccountTransactionStream) return;

    if (['brands', 'offers'].some(path => pathname.includes(path))) {
      redirect('/programs');
    }
  }, [hasAccountTransactionStream, redirect, pathname]);

  useEffect(() => {
    if (!hasProgramTransactionStream) return;

    if (pathname.includes('locations')) {
      redirect('/transactions');
    }
  }, [hasProgramTransactionStream, redirect, pathname]);

  return (
    <>
      <GoBackButtonContainer hasSubmenu={!!submenu}>
        <GoBackButton
          data-testid="go-back-btn"
          data-onboarding-target="menu-go-back"
          type="link"
          size="small"
          icon={<Caron />}
          onClick={() => {
            setSubmenu('');
            redirect('/programs');
          }}
        >
          {t('nav.menu')}
        </GoBackButton>
      </GoBackButtonContainer>
      <MenuContainer hasSubmenu={!!submenu}>
        <Menu theme="dark" selectedKeys={[pathname.slice(1)]}>
          {/* It’s not really dark, but dark is easier to customise */}
          {navItems
            .filter(({ visible, section }) => visible || section)
            .map(({ items, ...rest }) =>
              items ? (
                <SubMenu
                  key={`submenu-${rest.section}`}
                  title={rest.title}
                  visible={submenu === rest.section}
                >
                  {items
                    .filter(({ visible }) => visible)
                    .map(item =>
                      displayMenuItem(item, submenu === rest.section),
                    )}
                </SubMenu>
              ) : (
                displayMenuItem(rest, !submenu)
              ),
            )}
        </Menu>
      </MenuContainer>
    </>
  );
};

export default memoNoProps(Navigation);
