import React, { ReactNode, useContext } from 'react';
import { Trans } from 'react-i18next';
import { NotificationPayload } from '.';
import { OnboardingStepTypes } from '../onboarding-tour/onboarding-config';
import { OnboardingTourContext } from '../onboarding-tour/onboarding-provider';
import { NavLinkBlock } from './styled';

export const addUniqueOffer: NotificationPayload = {
  level: 'success',
  title: 'common.success.default',
  description: <Trans i18nKey="add.unique.description" ns="offers" />,
};

export const linkAllProgramsLocationsToOffer: NotificationPayload = {
  level: 'success',
  title: 'common.success.default',
  description: (
    <Trans
      ns="notifications"
      i18nKey="offers.linkAllProgramLocations.success"
    />
  ),
};

export const manageLocationsManually: NotificationPayload = {
  level: 'success',
  title: 'common.success.default',
  description: (
    <Trans
      ns="notifications"
      i18nKey="offers.manageLocationsManually.success"
    />
  ),
};

export const createOffer: NotificationPayload = {
  level: 'success',
  title: 'offers.createOffer.success',
};

export const updateOffer: NotificationPayload = {
  level: 'success',
  title: 'offers.updateOffer.success',
};

const PlaygroundLink = ({ children }: { children?: ReactNode }) => {
  const { setStep, step } = useContext(OnboardingTourContext);

  return (
    <NavLinkBlock
      to="/transactions"
      data-onboarding-target="playground-notification"
      data-testid="playground-notification-success-btn"
      onClick={() => {
        if (
          step === OnboardingStepTypes.PLAYGROUND_CLICK ||
          step === OnboardingStepTypes.PLAYGROUND
        )
          setStep(OnboardingStepTypes.DONE);
      }}
    >
      {children}
    </NavLinkBlock>
  );
};

export const newTransactionPlayground: NotificationPayload = {
  level: 'success',
  title: 'playground.success.title',
  description: (
    <Trans
      ns="notifications"
      i18nKey="playground.success.description"
      components={[<PlaygroundLink />]}
    />
  ),
  duration: 10,
};

export const updateBrand: NotificationPayload = {
  level: 'success',
  title: 'brands.updateBrand.title',
  description: (
    <Trans ns="notifications" i18nKey="brands.updateBrand.description" />
  ),
};

export const updateProfileNotification: NotificationPayload = {
  level: 'success',
  title: 'account.updateProfile.title',
  description: (
    <Trans
      ns="notifications"
      i18nKey="account.updateProfile.description"
      components={[<NavLinkBlock to="/account/profile" />]}
    />
  ),
};
