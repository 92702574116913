import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryCode, countryCodes } from '../../types';

import { actionToError } from '../utils/transform';

export const programTypes = [
  'transaction-select',
  'transaction-stream',
] as const;

export type ProgramType = typeof programTypes[number];

export interface Program {
  id: string;
  name: string;
  sync: boolean;
  syncStats: any;
  created: string;
  icon: string;
  iconBackground: string;
  type?: ProgramType;
}

export interface ProgramState {
  loading: boolean;
  programs: Record<string, Program>;
  programsByCountry: Record<CountryCode, Program[]>;
  creating: boolean;
  syncing: boolean;
  error?: any;
  lastProgram?: any;
}

export const initialState: ProgramState = {
  programs: {},
  programsByCountry: countryCodes.reduce(
    (countries, code) => ({ ...countries, [code]: [] }),
    {} as ProgramState['programsByCountry'],
  ),
  loading: false,
  creating: false,
  syncing: false,
};

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    clear: () => initialState,
    createProgram(state) {
      state.creating = true;
      state.error = undefined;
    },
    createProgramSuccess(state) {
      state.creating = false;
    },
    createProgramError(state, action: PayloadAction<any>) {
      state.creating = false;
      state.error = actionToError(action);
    },
    getPrograms(state) {
      state.loading = true;
      state.error = undefined;
      state.lastProgram = undefined;
    },
    getProgramsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.programs = action.payload.items.reduce(
        (result: any, item: Program) => {
          result[item.id] = item;
          return result;
        },
        {},
      );
      state.lastProgram = action.payload.last;
    },
    getProgramsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getProgramsByCountry(state) {
      state.loading = true;
      state.error = undefined;
    },
    getProgramsByCountrySuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.programsByCountry = {
        ...state.programsByCountry,
        ...action.payload,
      };
    },
    getProgramsByCountryError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    getProgram(state) {
      state.loading = true;
      state.error = undefined;
    },
    getProgramSuccess(state, action: PayloadAction<any>) {
      const program = action.payload.items[0];
      state.loading = false;
      state.programs = {
        ...state.programs,
        [program.id]: program,
      };
    },
    getProgramError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },
    updateProgram(state) {
      state.creating = true;
      state.error = undefined;
    },
    updateProgramSuccess(state) {
      state.creating = false;
    },
    updateProgramError(state, action: PayloadAction<any>) {
      state.creating = false;
      state.error = actionToError(action);
    },
    syncProgram(state) {
      state.syncing = true;
      state.error = undefined;
    },
    syncProgramSuccess(state, action: PayloadAction<any>) {
      const program = action.payload.items[0];
      state.syncing = false;
      state.programs = {
        ...state.programs,
        [program.id]: program,
      };
    },
    syncProgramError(state, action: PayloadAction<any>) {
      state.syncing = false;
      state.error = actionToError(action);
    },
  },
});

export const { actions } = programsSlice;
export default programsSlice.reducer;
