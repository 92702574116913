import * as Sentry from '@sentry/browser';
import { onAction } from '../utils/action-listen-middleware';
import { actionToError } from '../utils/transform';

export const sendErrorToSentry = (error: any) => Sentry.captureException(error);

const sendActionToSentry = (action: any) => () => {
  Sentry.withScope(scope => {
    scope.setExtras(actionToError(action));
    Sentry.captureException(new Error(action.actionName || action.type));
  });
};

export default onAction(
  actionType => actionType.endsWith('Error') && actionType !== 'clearError',
  sendActionToSentry,
);
