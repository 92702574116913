import download from 'downloadjs';

import { DateRange } from '../store/filters/filters-reducer';
import {
  toExportCardsData,
  toExportTransactionsData,
  withCurrency,
} from '../utils/transform';
import { toReportDate, toFileDate } from '../utils/dates';
import { TFunction } from '../types';

export interface ExportOptions {
  format: string;
  options: any[];
  programName: string;
  raw: any[];
  dateRange?: DateRange;
}

export interface CSVOptions {
  data: any[];
  programName: string;
  fileName: string;
}

export interface PDFOptions {
  type: string;
  rows: any[];
  programName: string;
  fileName: string;
  fileDate: string;
  total?: string;
}

class ExportService {
  options: any[] = [];
  t: TFunction;

  constructor(t: TFunction) {
    this.t = t;
  }

  private buildExportInfos({
    dateRange: { startDate, endDate } = {} as DateRange,
    type,
    programName,
  }: any) {
    let reportDate, fileDate;
    if (!!startDate && !!endDate) {
      reportDate = toReportDate(endDate, startDate);
      fileDate = toFileDate(endDate, startDate);
    } else {
      reportDate = fileDate = this.t(`export.${type}.all`);
    }

    const fileName = `${programName} - ${this.t(
      `export.${type}.report`,
    )} ${fileDate}`;

    return { reportDate, fileDate, fileName };
  }

  exportCards = ({
    options,
    format,
    raw,
    programName,
    dateRange,
  }: ExportOptions) => {
    this.options = options;
    const { reportDate, fileName } = this.buildExportInfos({
      dateRange,
      type: 'cards',
      programName,
    });

    if (format === 'pdf') {
      return this.createPDF({
        type: this.t('export.cards.title'),
        rows: toExportCardsData(raw),
        programName,
        fileName,
        fileDate: reportDate,
      });
    }

    return this.createCSV({
      data: toExportCardsData(raw, { formatted: false }),
      programName,
      fileName,
    });
  };

  exportTransactions = ({
    options,
    format,
    raw: rawTransactions,
    programName,
    dateRange,
  }: ExportOptions) => {
    this.options = options;
    const total =
      withCurrency(
        rawTransactions.reduce(
          (sum, tx) => sum + parseFloat(tx.amount || 0),
          0,
        ),
        rawTransactions[0].currency,
      ) || '';

    const { reportDate, fileName } = this.buildExportInfos({
      dateRange,
      type: 'transactions',
      programName,
    });

    let transactions = toExportTransactionsData(rawTransactions);
    if (format === 'pdf') {
      return this.createPDF({
        type: this.t('export.transactions.title'),
        rows: transactions,
        programName,
        fileName,
        fileDate: reportDate,
        total,
      });
    }
    transactions = toExportTransactionsData(rawTransactions, {
      formatted: false,
      includeCurrency: true,
    });

    return this.createCSV({ data: transactions, programName, fileName });
  };

  async createPDF({
    type,
    rows,
    programName,
    fileName,
    fileDate,
    total,
  }: PDFOptions) {
    const jsPDF = (await import('jspdf')).default;
    await import('jspdf-autotable');

    const doc: any = new jsPDF('l', 'pt');
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontType('bold');
    doc.setFontSize(26);
    doc.text(`${programName}`, 40, 50);
    doc.setFontSize(15);
    doc.text(`${this.t('export.label')}: ${fileDate}`, 40, 90);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(2);
    doc.line(40, 110, 800, 110);
    doc.text(`${this.t('export.total')} ${type}`, 40, 140);
    doc.text(`${rows.length}`, pageWidth - 40, 140, null, null, 'right');

    let margin = 180;

    if (typeof total !== 'undefined') {
      doc.text(this.t('export.totalVolume'), 40, margin);
      doc.text(total, pageWidth - 40, margin, null, null, 'right');
      doc.line(40, margin + 20, 800, margin + 20);
      margin += 40;
    }

    doc.autoTable(this.options, rows, {
      startY: margin,
      pageBreak: 'auto',
      margin: { top: 50 },
      showHead: 'firstPage',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [127, 129, 131],
      },
    });
    doc.save(`${fileName}.pdf`);
  }

  createCSV = ({ data, programName, fileName }: CSVOptions) => {
    const replacer = (key: string, value: string) =>
      value === null ? '' : value;
    const keys: string[] = [];
    const header: string[] = [];

    this.options.forEach(({ key, title }: any) => {
      keys.push(key);
      header.push(title);
    });

    const metadataKeys = Object.keys(data[0].metadata || []);

    metadataKeys.forEach(metadataKey =>
      header.push(`${programName} ${metadataKey}`),
    );

    const csv = data.map((row: any) => {
      let csvRow = keys.map(fieldName =>
        JSON.stringify(row[fieldName], replacer),
      );

      if (row.metadata)
        csvRow = csvRow.concat(
          metadataKeys.map(fieldName =>
            JSON.stringify(row.metadata[fieldName], replacer),
          ),
        );

      return csvRow.join(',');
    });

    csv.unshift(header.join(','));
    const csvStr = csv.join('\r\n');
    download(new Blob([csvStr]), `${fileName}.csv`, 'text/csv');
  };
}

export default ExportService;
