import React, { ReactNode, useState } from 'react';

import { X } from '../../icons';
import { Container, StyledInput, StyledTag } from './styled';

interface TaggedInputProps {
  name?: string;
  value?: string[];
  onChange: (values: string[]) => unknown;
  onBlur?: () => unknown;
}

function split(text: string) {
  return text.split(/[\s,]/);
}

const TaggedInput = ({
  name,
  value = [],
  onChange,
  onBlur,
}: TaggedInputProps) => {
  const [trailingText, setTrailingText] = useState('');

  function addValues(newValues: string[]) {
    const nonEmptyNewValues = newValues.filter(newValue => newValue !== '');

    if (nonEmptyNewValues.length > 0) {
      onChange([...value, ...nonEmptyNewValues]);
    }
  }

  function handleBlur() {
    const newValues = split(trailingText);

    setTrailingText('');
    addValues(newValues);

    if (onBlur) {
      onBlur();
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValues = split(e.target.value);
    const lastValue = newValues.pop();

    setTrailingText(lastValue || '');
    addValues(newValues);
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    const isBackspace = e.keyCode === 8 || e.key === 'Backspace';
    if (isBackspace && trailingText === '') {
      e.preventDefault();

      const newValues = [...value];
      const lastValue = newValues.pop();

      setTrailingText(lastValue || '');
      onChange(newValues);
    }
  }

  const tags = value.map((item, idx) => {
    const remove = () => {
      const newValues = [...value];
      newValues.splice(idx, 1);
      onChange(newValues);
      handleBlur();
    };

    return (
      <TaggedInputTag key={idx} onClose={remove}>
        {item}
      </TaggedInputTag>
    );
  });

  return (
    <Container className="ant-input">
      {tags}
      <StyledInput
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={trailingText}
      />
    </Container>
  );
};

interface TaggedInputTagProps {
  onClose: () => void;
  children?: ReactNode;
}

export const TaggedInputTag = ({ onClose, children }: TaggedInputTagProps) => (
  <StyledTag
    closable
    visible
    closeIcon={
      <X data-testid="tagged-input-close" style={{ fontSize: '12px' }} />
    }
    onClose={onClose}
  >
    {children}
  </StyledTag>
);

export default TaggedInput;
