import { FiltersProps } from '../../../components/filters';
import { FilterName } from '../../../components/filters/components/FilterDropdown';
import { OfferType } from '../../../store/offers/offers-model';

const offerHeadings = [
  'brandName',
  'created',
  'endDate',
  'inlineActions',
  'locationsTotal',
  'programName',
  'programsLinkCount',
  'requestedDate',
  'returnPeriod',
  'reward',
  'status',
] as const;
export type OfferHeading = typeof offerHeadings[number];

const otherHeadings: OfferHeading[] = [
  'brandName',
  'reward',
  'returnPeriod',
  'endDate',
  'programName',
];

export const columnsByTab: Record<OfferType, OfferHeading[]> = {
  marketplace: [
    'brandName',
    'reward',
    'locationsTotal',
    'created',
    'programsLinkCount',
  ],
  requests: ['brandName', 'requestedDate', 'status', 'programName'],
  awaitingApproval: [
    'brandName',
    'requestedDate',
    'programName',
    'inlineActions',
  ],
  upcoming: otherHeadings,
  live: otherHeadings,
  expired: otherHeadings,
};

const defaultFilters: FilterName[] = ['countryCode', 'contentProviderId'];

const moreFilters: FilterName[] = [
  'activation',
  'approval',
  'network',
  'customerRecency',
  'endDate',
  'returnPeriod',
];

export const filtersByTab: Record<OfferType, FiltersProps> = {
  marketplace: {
    defaultFilters: [
      'uniqueBrandId',
      'marketplaceCountries',
      'contentProviderId',
      'availableProgramId',
    ],
    moreFilters: [...moreFilters, 'channel'],
  },
  requests: {
    defaultFilters: ['brandId', ...defaultFilters, 'programId', 'offerStatus'],
    moreFilters: [...moreFilters, 'channelWithNotSpecified'],
  },
  awaitingApproval: {
    defaultFilters: [],
    moreFilters: [],
  },
  upcoming: {
    defaultFilters: ['brandId', ...defaultFilters, 'programId'],
    moreFilters: [...moreFilters, 'channelWithNotSpecified'],
  },
  live: {
    defaultFilters: ['brandId', ...defaultFilters, 'programId'],
    moreFilters: [...moreFilters, 'channelWithNotSpecified'],
  },
  expired: {
    defaultFilters: ['brandId', ...defaultFilters, 'programId'],
    moreFilters: [...moreFilters, 'channelWithNotSpecified'],
  },
};
