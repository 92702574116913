import React, { useEffect, useState, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { getContentProviders } from '../../store/offers';
import memoNoProps from '../../utils/memo-no-props';
import { OnSelectProps, OfferProps, OfferAction } from './types';
import { OffersMarketplaceOnboardingTour } from './offers-marketplace-onboarding';
import { OfferType, offerTypes } from '../../store/offers/offers-model';
import { getProgramsByCountry } from '../../store/programs/programs-actions';
import OffersList from './offers-list';
import CreateUpdateOffer from './create-update-offer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function isOfferType(type: string): type is OfferType {
  return offerTypes.includes(type as OfferType);
}

const Offers = () => {
  const dispatch = useAppDispatch();
  const { type } = useParams<{ type: OfferType }>();

  const [action, setAction] = useState<OfferAction>(null);
  const [offer, setOffer] = useState<OfferProps>(null);

  const { marketplace, isLive, contentProviders, isContentProvider } =
    useAppSelector(
      state => ({
        marketplace: state.account.marketplace,
        isLive: state.live,
        contentProviders: state.offers.contentProviders,
        isContentProvider: !!state.account.contentProviderInfo,
      }),
      shallowEqual,
    );

  const { hasMarketplaceAccess, countryCodes } = marketplace;

  const navItems: OfferType[] = ['requests', 'upcoming', 'live', 'expired'];
  if (hasMarketplaceAccess) {
    navItems.push('marketplace');
  }
  if (isContentProvider) {
    navItems.push('awaitingApproval');
  }
  const isInvalidPath = type && !navItems.includes(type);

  const onSelect: OnSelectProps = useMemo(
    () => ({
      get: { action, offer },
      set: {
        action: setAction,
        offer: setOffer,
      },
    }),
    [action, offer],
  );

  useEffect(() => {
    if (hasMarketplaceAccess) dispatch(getProgramsByCountry(countryCodes));
  }, [dispatch, hasMarketplaceAccess, countryCodes, isLive]);

  useEffect(() => {
    if (!contentProviders.length) dispatch(getContentProviders());
  }, [dispatch, contentProviders]);

  function onClose() {
    if (action) setAction(null);
    if (offer) setOffer(null);
  }

  if (isInvalidPath || !isOfferType(type))
    return <Redirect to={`/offers/${navItems[0]}`} />;

  return (
    <>
      <OffersMarketplaceOnboardingTour>
        <>
          <OffersList onSelect={onSelect} />
          <CreateUpdateOffer
            visible={!!action}
            offer={offer}
            onClose={onClose}
          />
        </>
      </OffersMarketplaceOnboardingTour>
    </>
  );
};

export default memoNoProps(Offers);
