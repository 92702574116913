import { AxiosPromise } from 'axios';
import { getAllItems, getCount } from '../utils/pagination';
import http from '../../services/http-service';

import { api, namespaces } from '../../config';
import {
  Offer,
  OfferOptions,
  OfferSupplierFields,
  OfferCardActivation,
  OfferType,
  offerTypes,
} from './offers-model';
import { ApiResponse } from '../../types';

async function extractData(response: AxiosPromise<any>) {
  return (await response).data;
}

export const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

function parseParams(
  options: OfferOptions = {},
  filters: URLSearchParams = new URLSearchParams(),
) {
  const params: Record<string, any> = { ...options };
  delete params.last;

  const headers: Record<string, string> = {};

  const filterParams = new URLSearchParams(filters);

  const user = localStorage.getItem(namespaces.USER);
  if (user) headers['fidel-user'] = user;

  const { last, select } = options;

  if (last) {
    params.start = JSON.stringify(last);
  }

  if (!select) delete params.select;

  Object.entries(params).forEach(([key, value]) =>
    filterParams.append(key, value as string),
  );

  return { params: filterParams, headers };
}

function getOffersByTypeUrl(type: OfferType, isContentProvider = false) {
  if (type === 'marketplace') return 'unique-offers';
  if (type === 'awaitingApproval' && isContentProvider)
    return 'unique-offers/approvals';
  return 'offers';
}

export default class OfferApiService {
  public async getAllOffers(
    options?: OfferOptions,
    params: URLSearchParams = new URLSearchParams(),
  ) {
    return getAllItems(last =>
      http.get('offers', parseParams({ ...options, last }, params)),
    );
  }

  public async getOffers(
    {
      count,
      isContentProvider,
    }: {
      count?: boolean;
      isContentProvider?: boolean;
    } = { count: false, isContentProvider: false },
  ): Promise<Record<OfferType, any>> {
    const responses = await Promise.all(
      offerTypes.map(type =>
        extractData(
          this.getOffersByType({
            type,
            select: count ? 'count' : undefined,
            isContentProvider,
          }),
        ),
      ),
    );

    return offerTypes.reduce(
      (acc, type, idx) => ({ ...acc, [type]: responses[idx] }),
      {} as any,
    );
  }

  public getOffersByType({
    type,
    last,
    select,
    filters = new URLSearchParams(),
    isContentProvider = false,
  }: {
    type: OfferType;
    last?: any;
    select?: string | undefined;
    filters?: URLSearchParams;
    isContentProvider?: boolean;
  }) {
    return http.get<ApiResponse<Offer>>(
      getOffersByTypeUrl(type, isContentProvider),
      parseParams(
        {
          ...(type !== 'awaitingApproval' && {
            status: type === 'marketplace' ? 'pending' : type,
          }),
          last,
          select,
        },
        filters,
      ),
    );
  }

  public createOffer(offer: Offer) {
    return http.post('offers', offer);
  }

  public getOffer(offerId: string) {
    const headers: any = {};
    const user = localStorage.getItem(namespaces.USER);

    if (user) headers['fidel-user'] = user;

    return http.get(`offers/${offerId}`, { headers });
  }

  public updateOffer(offerId: string, offer: Partial<Offer>) {
    return http.patch(`offers/${offerId}`, offer);
  }

  public linkCardToOffer(cardId: string, offerId: string) {
    return http.post(`offers/${offerId}/cards/${cardId}`, {});
  }

  public linkLocation(locationId: string, offerId: string) {
    return http.post(`offers/${offerId}/locations/${locationId}`, {});
  }

  public unlinkLocation(locationId: string, offerId: string) {
    return http.delete(`offers/${offerId}/locations/${locationId}`);
  }

  public getLocations(offerId: string, last?: any) {
    let params: any = {};
    if (last) params = { ...params, start: last };

    return http.get(`offers/${offerId}/locations`, { params });
  }

  public getOfferLocationsCount(offerId: string) {
    return getCount(last => {
      const url = `offers/${offerId}/locations`;

      const params: any = {
        select: 'count',
      };

      if (last) params.start = JSON.stringify(last);

      return http.get(url, { params });
    }, api.MAX_LOCATIONS_COUNT);
  }

  public async getAllLocations(offerId: string) {
    return getAllItems(last => this.getLocations(offerId, last));
  }

  public async getContentProviders() {
    return http.get('offers-content-providers');
  }

  public async getReportingProviders() {
    return http.get('offers-reporting-providers');
  }

  public async getProgramsWithLinkedLocations(
    offerId: string,
    programIds: string[],
  ) {
    return programIds.map(programId =>
      http.get(`offers/${offerId}/locations`, {
        params: {
          programId,
        },
      }),
    );
  }

  public async linkAllProgramsLocationsToOffer(
    offerId: string,
    programIds: string[],
  ) {
    return programIds.map(programId =>
      http.post(`offers/${offerId}/programs/${programId}/locations`, {}),
    );
  }

  public getUniqueLocations(uniqueOfferId: string, last?: any) {
    let params: any = {};
    if (last) params = { ...params, start: last };

    return http.get(`unique-offers/${uniqueOfferId}/unique-locations`, {
      params,
    });
  }

  public async getAllUniqueLocations(uniqueOfferId: string) {
    return getAllItems(last => this.getUniqueLocations(uniqueOfferId, last));
  }

  public addUniqueOffer(
    id: string,
    programIds: string[],
    supplierFields: OfferSupplierFields | undefined,
    activation: OfferCardActivation | undefined,
  ) {
    return http.post(`unique-offers/${id}/offers`, {
      programIds,
      supplierFields,
      activation,
    });
  }
}
